import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "./index.css"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="container">
      <div className="sections">
        <h2 className="section-title">Skills</h2>
        <div className="list-card">
          <span className="exp">+ 7 years</span>
          <div>
            <h3>Programming language &amp; frameworks</h3>
            <span>
              TS/JS (Node.js backend), NestJS, KeystoneJS, Socket.IO, AdonisJS,
              Prisma, GraphQL
            </span>
          </div>
        </div>
        <div className="list-card">
          <span className="exp">+ 4 years</span>
          <div>
            <h3>Deployment tools</h3>
            <span>
              PM2, Docker, Portainer, Nginx Proxy Manager, Gitlab, Heroku
            </span>
          </div>
        </div>
      </div>
      <div className="sections">
        <h2 className="section-title">Interests</h2>
        <div className="list-card">
          <div>
            <h3>Languages</h3>
            <span>TS/JS, Go, Java</span>
          </div>
        </div>
        <div className="list-card">
          <div>
            <h3>Hacking</h3>
            <span>API (REST/GraphQL/Websocket), Microservices, Library</span>
          </div>
        </div>
      </div>
    </div>
    <div className="container cards">
      <div className="card">
        <div className="skill-level">
          <span>+</span>
          <h2>20</h2>
        </div>
        <div className="skill-meta">
          <h3>Projects</h3>
          <span>Create projects for open source, enterprise or freelance</span>
        </div>
      </div>
      <div className="card">
        <div className="skill-level">
          <h2>60</h2>
          <span>%</span>
        </div>
        <div className="skill-meta">
          <h3>Application Architecture</h3>
          <span>
            Such as designing and manufacturing microservice-based or
            monolithic-based systems
          </span>
        </div>
      </div>
      <div className="card">
        <div className="skill-level">
          <h2>60</h2>
          <span>%</span>
        </div>
        <div className="skill-meta">
          <h3>Technical solutions</h3>
          <span>Such as web services, scripts, configurations</span>
        </div>
      </div>
      <div className="card">
        <div className="skill-level">
          <h2>20</h2>
          <span>%</span>
        </div>
        <div className="skill-meta">
          <h3>Leading</h3>
          <span>Ensuring application quality and optimization</span>
        </div>
      </div>
    </div>
    <div className="container">
      <ol className="timeline">
        <li>
          <p className="line">Experiences</p>
          <span className="point"></span>
          <a className="description" href="https://intama.info" target="_blank">
            Software Engineer @ Intama
          </a>
          <span className="date">Today - Jan. 2022</span>
        </li>
        <li>
          <span className="point"></span>
          <a
            className="description"
            href="https://azsolusindo.info"
            target="_blank"
          >
            Software Engineer @ AZ Solusindo Teknologi
          </a>
          <span className="date">Sep. 2018 - Dec. 2021</span>
        </li>
        <li>
          <span className="point"></span>
          <a
            className="description"
            href="https://mykohotel.com"
            target="_blank"
          >
            IT Staff @ MYKO Hotel Convention Center, Makassar
          </a>
          <span className="date">Jul. 2018 - Sep. 2018</span>
        </li>
      </ol>
    </div>
  </Layout>
)

export default IndexPage
